import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { BsCart } from 'react-icons/bs';
import { FaRegUser } from 'react-icons/fa6';
import { Container } from 'react-bootstrap';
import './Header.css';

const Header = () => {
    const [username, setUsername] = useState(localStorage.getItem('username') || "");
    const [cartCount, setCartCount] = useState(localStorage.getItem('cart_id') || "");

    useEffect(() => {
        // Fetch cart count from the API endpoint
        const fetchCartCount = async () => {
            try {
                const response = await fetch('https://itilmaamapi.adef.tech/Cartcount?cart_id');
                const data = await response.json();
                // Update cart count state
                setCartCount(data.cartCount);
            } catch (error) {
                console.error('Error fetching cart count:', error);
            }
        };

        fetchCartCount(); // Call the fetchCartCount function when component mounts
    }, []);

    return (
        <Container>
            <header className="header">
                <Link to="/" className="header__logo"> {/* Wrap the logo in a Link component */}
                    ITILMAAM
                </Link>
                <div className="header__search">
                    <input type="text" placeholder="search with keyword" className="header__searchInput" />
                    <FontAwesomeIcon icon={faSearch} className="header__searchIcon" />
                </div>
                <div className="header__icons">
                    <div className="header__icon">
                        <Link className='link' to="/cart">
                            <BsCart />
                            <span>Cart ({cartCount})</span>
                        </Link>
                    </div>
                    {username ? (
                        <div className="header__icon">
                            <Link to="/profile"> {/* Link to user's profile page */}
                                <FaRegUser />
                                <span>{username}</span> {/* Display user's name */}
                            </Link>
                        </div>
                    ) : (
                        <div className="header__icon">
                            <Link className='link' to="/login"> {/* Use Link component to redirect */}
                                <FaRegUser />
                                <span>Signin</span>
                            </Link>
                        </div>
                    )}
                </div>
            </header>
        </Container>
    );
};

export default Header;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { Container, Row, Col } from 'react-bootstrap';
import "./Css/Delivery.css";
import { Link } from 'react-router-dom';
import OrderDetails from './OrderDetails';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DeliveryAddress = () => {
  const [primaryAddress, setPrimaryAddress] = useState(null);
  if(primaryAddress!=null){
    localStorage.setItem("addressbookid", primaryAddress.id);
  }
 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCartItem, setSelectedCartItem] = useState(null);
  const [cart, setCart] = useState([]);
  const [orderDetails, setOrderDetails] = useState({});
  const [couponCode, setCouponCode] = useState('');
  const [appliedCoupon, setAppliedCoupon] = useState(null);

  useEffect(() => {
    const fetchPrimaryAddress = async () => {
      try {
        // Get the author_id from local storage
        const authorId = localStorage.getItem('author_id');

        // Fetch delivery addresses using the provided API endpoint
        const response = await axios.get(
          `https://itilmaamapi.adef.tech/List_Address?authorid=${authorId}`
        );

        // Check if the response is successful
        if (response.status === 200) {
          // Filter the delivery addresses to get the primary address
          const primaryAddress = response.data.find(address => address.isprimary === true);
          setPrimaryAddress(primaryAddress);
        
        } else {
          // Handle unsuccessful response
          setError('Failed to fetch delivery addresses');
        }
      } catch (error) {
        // Handle errors
        console.error('Error fetching delivery addresses:', error);
        setError('Error fetching delivery addresses');
      } finally {
        // Set loading to false
        setLoading(false);
      }
    };

    // Call the fetchPrimaryAddress function
    fetchPrimaryAddress();
  }, []);

  useEffect(() => {
    const fetchCartDetails = async () => {
      try {
        const cartId = localStorage.getItem("cart_id");
        const authorId = localStorage.getItem("author_id");

        if (!cartId || !authorId) {
          throw new Error("Cart ID or Author ID not found in localStorage");
        }

        const cartResponse = await axios.get(
          `https://itilmaamapi.adef.tech/Cartlist?cart_id=${cartId}&author_id=${authorId}`
        );

        if (cartResponse.status === 200) {
          setCart(cartResponse.data);
          setSelectedCartItem(cartResponse.data[0]); // Set the first item as selected initially
        }

        const orderResponse = await axios.get(
          `https://itilmaamapi.adef.tech/Cartamount?cart_id=${cartId}`
        );

        if (orderResponse.status === 200) {
          setOrderDetails(orderResponse.data);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching cart details:", error);
        setError("Error fetching cart details");
      }
    };

    fetchCartDetails();
  }, []);

  // Function to handle applying coupon code
  const handleApplyCoupon = () => {
    // Here you would implement logic to validate and apply the coupon code
    // For simplicity, let's assume the coupon code is valid and provides a fixed discount
    const couponDiscount = 50; // Example fixed discount amount
    setAppliedCoupon({
      code: couponCode,
      discount: couponDiscount
    });

    // Recalculate the order total after applying the coupon
    const newOrderDetails = {
      ...orderDetails,
      total_cart_amount: orderDetails.total_cart_amount - couponDiscount
    };
    setOrderDetails(newOrderDetails);
  };

  // Function to create bill upon proceeding to shipping
  const handleProceedToShipping = () => {
    // Retrieve required parameters from local storage
    const authorId = localStorage.getItem('authorId');
    const cartId = localStorage.getItem('cartId');
    const addressBookId = localStorage.getItem('addressbookid');

    // Check if all required parameters are available
    if (authorId && cartId && addressBookId) {
      const data = {
        authorid: authorId,
        totalamount: orderDetails.total_cart_amount, // Use total cart amount
        addressbook_id: addressBookId,
        cart_id: cartId,
        usertype: 4
      };

      // Make API request to create bill
      fetch('https://itilmaamapi.adef.tech/Create_Bill', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
      .then(response => {
        if (response.ok) {
          // Show success notification
          toast.success('Order placed successfully!');
          // Redirect to orders page or any other page as needed
        } else {
          // Show error notification
          toast.error('Failed to create order. Please try again later.');
        }
      })
      .catch(error => {
        console.error('Error creating order:', error);
        // Show error notification
        toast.error('An error occurred while placing the order.');
      });
    } else {
      // Show error notification if any parameter is missing
      toast.error('Missing required parameters to place the order.');
    }
  };

  return (
    <Container>
      <Row>
        <Col md={9}>
          <div className="delivery-address-container">
            <h2 className="delivery-address-heading"><FaMapMarkerAlt /> Delivery Address</h2>
            {loading ? (
              <p>Loading...</p>
            ) : error ? (
              <p className="delivery-address-error">{error}</p>
            ) : (
              primaryAddress ? (
                <div>
                  <p className='delivery-default'>Default</p>

                  <h4 className="delivery-address-name">{primaryAddress.name}</h4>
                  <p className="delivery-address-details">{primaryAddress.address}</p>
                  <p className="delivery-address-details">{primaryAddress.city}, {primaryAddress.state} - {primaryAddress.pincode}</p>
                  <p className="delivery-address-details">Phone: {primaryAddress.phone}</p>
                  <Link to="/address" className="button-product">
                    Change Address
                  </Link>
                </div>
              ) : (
                <p className="delivery-address-error">No primary address found</p>
              )
            )}
          </div>
        </Col>
        <Col md={3}>
          {cart.length > 0 && (
            <OrderDetails
              selectedCartItem={selectedCartItem}
              orderDetails={orderDetails}
              couponCode={couponCode}
              setCouponCode={setCouponCode}
              handleApplyCoupon={handleApplyCoupon}
              handleProceedToShipping={handleProceedToShipping} // Pass handleProceedToShipping as a prop
            />
          )}
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
};

export default DeliveryAddress;

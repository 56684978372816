import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";

function OTPVerification({ email, usertype }) {
  const [otp, setOTP] = useState("");

  const handleOTPChange = (e) => {
    setOTP(e.target.value);
  };

  const handleVerifyOTP = async () => {
    try {
      const url = "https://itilmaamapi.adef.tech/Otp_Login";
      const data = new URLSearchParams({
        email: email,
        usertype: "4",
        OTP: otp,
      });

      const response = await fetch(url, {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      const responseData = await response.json();

      if (responseData.responseCode == 1) {
        localStorage.setItem("author_id", responseData.responseid);
        localStorage.setItem("usertype", responseData.responsetype);
        localStorage.setItem("username", responseData.responseobject);
        // OTP verification successful
        console.log("OTP verified successfully");
        toast.success("OTP verified successfully");
        // Redirect to the home page
        window.location.href = "/home";
      } else {
        // OTP verification failed
        console.log("OTP verification failed");
        toast.error("OTP verification failed");
      }
    } catch (error) {
      console.error("Error during OTP verification:", error);
      toast.error("An error occurred during OTP verification");
    }
  };

  return (
    <Form>
      <p>Enter OTP sent to {email}</p>
      <Form.Group controlId="formBasicOTP">
        <Form.Control
          type="text"
          placeholder="Enter OTP"
          value={otp}
          onChange={handleOTPChange}
        />
      </Form.Group>
      <Button
        className="form-control btn btn-success mt-2"
        onClick={handleVerifyOTP}
      >
        Verify OTP
      </Button>
    </Form>
  );
}

export default OTPVerification;

import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './components/Home';
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import ProductList from "./components/ProductList";
import ProductDetails from "./components/ProductDetails";
import Footer from './components/Footer';
import Signin from './components/Signin';
import Faqspages from './components/Faqspages';
import Contact from './components/Contact'
import Login from './components/Login';
import Profile from './components/Profile';
import Address from './components/Address';
import Checkout from './components/Checkout';
import Cart from './components/Cart';
import AddAddress from './components/AddAddress';
import EdtiAddress from './components/EditAddress';
import Delivery from './components/Delivery';
import Products from './components/Prodcuts';
import ShowProducts from './components/ShowProducts';
import ChangePassword from './components/ChangePassword';
import Customersupport from './components/Customersupport';
import MyOrders from './components/MyOrders';
import ForgotPassword from './components/ForgotPassword';
import OTPVerification from './components/OTP';

function App() {
  const userName = localStorage.getItem('userName');

  return (
    <div className="App">
      <BrowserRouter>
        <Header userName={userName} />
        <Navbar />
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/productslist/:categoryId" element={<ProductList />} />
          <Route path="/productdetails" element={<ProductDetails />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/productslist" element={<ProductList />} />
          <Route path="/productdetails" element={<ProductDetails />} /> 
          <Route path="/signin" element={<Signin />} /> 
          <Route path="/cart" element={<Cart />} /> 
          <Route path="/checkout" element={<Checkout />} /> 
          <Route path="/login" element={<Login />} />
          <Route path="/faqspages" element={<Faqspages />} />
          <Route path="/delivery" element={<Delivery />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/address" element={<Address />} />
          <Route path="/addaddress" element={<AddAddress />}/>
          <Route path="/EditAddress" element={<EdtiAddress/>}/>
          <Route path='/changepassword' element={<ChangePassword/>}/>
          <Route path='/customercare' element={<Customersupport/>}/>
          <Route path="/products" element={<ShowProducts />} />
          <Route path='/myorders' element={<MyOrders/>}/>
          <Route path="/forgotpassword" element={<ForgotPassword />} /> 
          <Route path="/otp" element={<OTPVerification />} /> 
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;

import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OTPVerification from "./OTP";
import "./Login.css";

function ForgotPassword() {
  const [formData, setFormData] = useState({
    email: "",
    usertype: "4",
  });
  const [showOTPComponent, setShowOTPComponent] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    const url = "https://itilmaamapi.adef.tech/ForgotPassword";
    const data = new URLSearchParams(formData);

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      const responseData = await response.json();

      if (responseData.responseCode === 0) {
        // OTP sent successfully, show OTP component
        setShowOTPComponent(true);
        toast.success(responseData.responsemessage, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        // OTP sending failed
        toast.error(
          responseData.responsemessage || "Failed to send OTP.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } catch (error) {
      // Error during OTP sending
      toast.error("An error occurred during OTP sending.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      console.error("Fetch error:", error);
    }
  };

  return (
    <Container fluid className="custom-container">
      <Row className="login" noGutters>
        <Col md={6} className="image-column mt-5">
          <img
            src="https://img.freepik.com/free-vector/computer-login-concept-illustration_114360-7862.jpg"
            alt="Login Image"
            className="img-fluid"
          />
        </Col>
        <Col md={6} className="form-column mt-5">
          <div className="form-wrapper">
            {showOTPComponent ? (
              <OTPVerification email={formData.email} />
            ) : (
              <Form className="login-pages" onSubmit={handleLogin}>
                <p>Login to your account</p>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    type="email"
                    placeholder="Email Address"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Button
                  className="form-control btn btn-success mt-2"
                  type="submit"
                >
                  Get OTP
                </Button>
                <div className="form-links">
                  <a href="/login" className="justify-content center">
                    Login
                  </a>
                </div>
              </Form>
            )}
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
}

export default ForgotPassword;

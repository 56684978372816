import React from "react";
import BrowserItems from "./BrowseItems";
import Products from "./Prodcuts"
import FeaturedProducts from "./FeaturedProducts";
import Faqs from "./Faqs";
import Listing from "./Listing";
import Testimonials from "./Testimonials";
const Home =()=>{
    return(
        <>
        <BrowserItems/>
        <Products/>
        <FeaturedProducts/>
        <Faqs/>
        <Listing/>
        <Testimonials/>
        </>
    )
}
export default Home;

import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import "./ProductDetails.css";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import axios from "axios";
import { FaRegHeart } from "react-icons/fa";
import { IoCartOutline } from "react-icons/io5";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


debugger;
function ProductDetails() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const productId = searchParams.get("productId");
  const [product, setProduct] = useState(null);
  const [categories, setCategories] = useState([]);
  const [showAllCategories, setShowAllCategories] = useState(false);
  const author_id = localStorage.getItem("author_id");
  const cart_id = localStorage.getItem("cart_id");
debugger;
  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(
          `https://itilmaamapi.adef.tech/Productview_Home?id=${productId}`
        );
        if (response.data.responseCode == 0) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = response.data;
        setProduct(data);
      } catch (error) {
        console.error("Error fetching product details:", error);
        setProduct(null);
      }
    };

    fetchProductDetails();
  }, [productId]);

  useEffect(() => {
    fetch("https://itilmaamapi.adef.tech/List_Category")
      .then((response) => response.json())
      .then((data) => setCategories(data))
      .catch((error) => console.error("Error fetching categories:", error));
  }, []);





  const addToCart = async () => {
    debugger;
    try {
      const response = await axios.post(
        "https://itilmaamapi.adef.tech/AddCart",
        {
          product_id: productId,
          quantity: 1,
          usertype: 4,
          authorid: author_id,
          cart_id: cart_id,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      debugger;
      if (response.data.responseCode == 0) {
        localStorage.setItem("cart_id", response.data.cart_id);
        toast.success(response.data.responsemessage);
        window.location.href = "/cart";
      } else {
        toast.error(response.data.responsemessage);
      }
    } catch (error) {
      console.error("Error :", error);
      toast.error("Error");
    }
  };
  
  
  


  return (
    <>
      <Container fluid className="p-0">
        <div className="breadcrumbs text-center">
          <p className="text-white">Home / SearchResults</p>
        </div>
      </Container>
      <Container fluid className="bg-details">
        <Container>
          {product ? (
            <Row className="pt-5">
              <Col md={9}>
                <div>
                  <Card className="p-4">
                    <div>
                      <h2 className="name">{product.name}</h2>
                      <p className="area">
                        <span>Somalia</span>
                      </p>
                    </div>
                    <div className="text-end d-flex justify-content-end socialmedialinks mb-4">
                      <p className="pt-2">Share</p>
                      <div className="mx-1">
                        <FacebookShareButton
                          url={`https://itilmaamapi.adef.tech/Productview_Home?id=${productId}`}
                        >
                          <FacebookIcon size={32} round />
                        </FacebookShareButton>
                      </div>
                      <div className="mx-1">
                        <TwitterShareButton
                          url={`https://itilmaamapi.adef.tech/Productview_Home?id=${productId}`}
                        >
                          <TwitterIcon size={32} round />
                        </TwitterShareButton>
                      </div>
                      <div className="mx-1">
                        <LinkedinShareButton
                          url={`https://itilmaamapi.adef.tech/Productview_Home?id=${productId}`}
                        >
                          <LinkedinIcon size={32} round />
                        </LinkedinShareButton>
                      </div>
                      <div className="mx-1">
                        <WhatsappShareButton
                          url={`https://itilmaamapi.adef.tech/Productview_Home?id=${productId}`}
                        >
                          <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                      </div>
                    </div>
                    <img
                      src={product.display_image}
                      className="productimg"
                      alt={product.name}
                    />
                    <Row>
                      <Col md={6} className="mt-4">
                        <Button className="whishlistbtn">
                          {" "}
                          Wishilist <span><FaRegHeart /></span>{" "}
                        </Button>
                      </Col>

                      <Col md={6} className="mt-4">
                        <Button className="cartbtn" onClick={addToCart}>
                          {" "}
                          ADD TO CART <span><IoCartOutline /></span>{" "}
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                  <Card className="mt-4 p-4">
                    <h4 className="overview">Overview</h4>
                    <p>{product.description}</p>
                  </Card>
                </div>
              </Col>
              <Col md={3}>
                <Card className="p-3">
                  <p>Price</p>
                  <p className="detailsprice">
                    {" "}
                    ${product.price} <span><del>${product.rate}</del></span>{" "}
                  </p>
                </Card>
                <Card className="filter-card text-center mt-5">
                  <h5 className="product-categories mt-5">More Categories</h5>
                  <Container>
                    <div className="container-fluid mt-5">
                      <div className="row">
                        {categories
                          .slice(
                            0,
                            showAllCategories
                              ? categories.length
                              : 6
                          )
                          .map((category) => (
                            <div className="col-12" key={category.id}>
                              <Link
                                to={`/productslist?id=${category.id}`}
                                className="card1"
                              >
                                <div className="cateory_div">
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex">
                                      {category.image && (
                                        <img
                                          src={category.image}
                                          alt={category.name}
                                          className="image-icon"
                                        />
                                      )}
                                      <p className="">{category.name}</p>
                                    </div>
                                    <div className="">
                                      <p>{category.productcount}</p>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          ))}
                      </div>
                      {!showAllCategories &&
                        categories.length > 6 && (
                          <div className="text-start mb-3 mt-3">
                            <Button
                              className="button-product"
                              onClick={() =>
                                setShowAllCategories(true)
                              }
                            >
                              Show More
                            </Button>
                          </div>
                        )}
                    </div>
                  </Container>
                </Card>
              </Col>
            </Row>
          ) : (
            <p>Loading...</p>
          )}
          <ToastContainer />
        </Container>
      </Container>
    </>
  );
}

export default ProductDetails;

import React from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import Image from "./Images/bg.png";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import "./Css/Listing.css";
import image1 from "./Images/bg1.png";
import image2 from "./Images/overley.png";
import image3 from "./Images/overley1.png";
import { CiLocationOn } from "react-icons/ci";
const Listing = () => {
    debugger;
    //const [data, setData] = useState([]);

    // const fetchFeaturedProducts = () => {
    //     fetch("https://itilmaamapi.adef.tech/FeaturedProduct")
    //         .then(response => response.json())
    //         .then(data => setData(data.slice(0, 4)))
    //         .catch(error => console.error("Error fetching products:", error));
    // };


    return (
        <>
            <Container className="mt-5">
                <Row>
                    <Col md={6}>
                        <div>
                            <p className="title_small mb-0">Hand-Picked</p>
                            <h3 className="mb-4 getevery">Hand-picked <span className="category">listings</span> </h3>
                        </div>
                        <Carousel fade prevIcon={<FaArrowLeft />} nextIcon={<FaArrowRight />} interval={null}>
                            <Carousel.Item>
                                <img src={image2} className="curoselimage" alt="" />
                                <Carousel.Caption>
                                <p  className="location"><CiLocationOn /> <span>Somalia</span></p>
                                    <p>New downtown house with cool pool</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={Image} className="curoselimage" alt="" />
                                <Carousel.Caption>
                                <p  className="location"><CiLocationOn /> <span>Somalia</span></p>
                                    <p>New downtown house with cool pool</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={image2} className="curoselimage" alt="" />
                                <Carousel.Caption>
                                <p  className="location"><CiLocationOn /> <span>Somalia</span></p>
                                    <p>New downtown house with cool pool</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                        <div className="d-flex mt-3">
                            <div className="">
                                <img src={image1} className="image1" alt="" />
                            </div>
                            <div className="">
                                <div className='location'>
                                    <p><CiLocationOn /> <span>Somalia</span></p>
                                </div>
                                <h6>Itox minimal 6000 litter freezer </h6>
                                <p className="location">$4200</p>
                            </div>
                        </div>
                        <div className="d-flex mt-3">
                            <div className="">
                                <img src={image1} className="image1" alt="" />
                            </div>
                            <div className="">
                                <div className='location'>
                                    <p ><CiLocationOn /> <span>Somalia</span></p>
                                </div>
                                <h6>Itox minimal 6000 litter freezer </h6>
                                <p className="location">$4200</p>
                            </div>
                        </div>
                        <div className="d-flex mt-3">
                            <div className="">
                                <img src={image1} className="image1" alt="" />
                            </div>
                            <div className="">
                                <div className='location'>
                                    <p ><CiLocationOn /> <span>Somalia</span></p>
                                </div>
                                <h6>Itox minimal 6000 litter freezer </h6>
                                <p className="location">$4200</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div>
                            <p className="title_small mb-0">Recently added</p>
                            <h3 className="mb-4 getevery">Recently added<span className="category">listings</span> </h3>
                        </div>


                        <Carousel fade prevIcon={<FaArrowLeft />} nextIcon={<FaArrowRight />} interval={null}>
                            <Carousel.Item>
                                <img src={image3} className="curoselimage" alt="" />
                                <Carousel.Caption>
                                <p className="location"><CiLocationOn /> <span>Somalia</span></p>
                                    <p>New downtown house with cool pool</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={Image} className="curoselimage" alt="" />
                                <Carousel.Caption>
                                <p  className="location"><CiLocationOn /> <span>Somalia</span></p>
                                    <p>New downtown house with cool pool</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={image3} className="curoselimage" alt="" />
                                <Carousel.Caption>
                                <p  className="location"><CiLocationOn /> <span>Somalia</span></p>
                                    <p>New downtown house with cool pool</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                           <div className="d-flex mt-3">
                            <div className="">
                                <img src={image1} className="image1" alt="" />
                            </div>
                            <div className="">
                                <div className='location'>
                                    <p><CiLocationOn /> <span>Somalia</span></p>
                                </div>
                                <h6>Itox minimal 6000 litter freezer </h6>
                                <p className="location">$4200</p>
                            </div>
                        </div>
                        <div className="d-flex mt-3">
                            <div className="">
                                <img src={image1} className="image1" alt="" />
                            </div>
                            <div className="">
                                <div className='location'>
                                    <p><CiLocationOn /> <span>Somalia</span></p>
                                </div>
                                <h6>Itox minimal 6000 litter freezer </h6>
                                <p className="location">$4200</p>
                            </div>
                        </div>
                          <div className="d-flex mt-3">
                            <div className="">
                                <img src={image1} className="image1" alt="" />
                            </div>
                            <div className="">
                                <div className='location'>
                                    <p><CiLocationOn /> <span>Somalia</span></p>
                                </div>
                                <h6>Itox minimal 6000 litter freezer </h6>
                                <p className="location">$4200</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default Listing;
import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Signin.css";
function Sign({ onSignUpSuccess }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    Phone:"",
    address:"",
    usertype: "4",
  });
  const [passwordError, setPasswordError] = useState("");
  const [isTermsChecked, setIsTermsChecked] = useState(false);

  debugger;
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === "password") {
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    const url = "https://itilmaamapi.adef.tech/Create_User";
    const data = new URLSearchParams(formData);

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      const responseData = await response.json();

      if (response.ok) {
        toast.success("Sign up successful!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        window.location.href = "/login";
        // Clear form data
        setFormData({
          name: "",
          email: "",
          password: "",
        });
      } else {
        toast.error(
          responseData.responsemessage ||
          "An error occurred during registration.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } catch (error) {
      toast.error("An error occurred during registration.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      console.error("Fetch error:", error);
    }
  };

  return (
    <Container fluid className="custom-container">
      <Row className="login">
      <Col md={6} className="form-column">
          <div className="form-wrapper">
            <Form className="login-pages" onSubmit={handleSignUp}>
              <p>Create a new account</p>
              <Form.Group controlId="formBasicName">
                <Form.Control
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  placeholder="Email Address"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formBasicarea">
                <Form.Control
                  type="text"
                  placeholder="Area"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formBasicnbr">
                <Form.Control
                  type="number"
                  placeholder="Phone Number"
                  name="Phone"
                  value={formData.Phone}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
              </Form.Group>
              {passwordError && (
                <Form.Text className="text-danger">
                  {passwordError}
                </Form.Text>
              )}


              <Form.Group controlId="formBasicTerms">
                <label className="d-flex">
                  <input type="checkbox" id="terms"
                    className="check_box"
                    checked={isTermsChecked}
                    onChange={() => setIsTermsChecked(!isTermsChecked)}
                  />
                  I agree to the{" "}
                  <Link to="/termsofuse">terms and conditions</Link>
                </label>
              </Form.Group>

              <Button
                className="form-control btn btn-success mt-2"
                type="submit"
                disabled={!isTermsChecked}
              >
                Sign Up
              </Button>

              <div className="form-links">
                <Link className="small text-muted" to="/login">
                  Already have an account? Login here
                </Link>
              </div>
            </Form>
          </div>
        </Col>
        <Col md={6} className="image-column mt-5">
          <img
            src="https://img.freepik.com/free-vector/tablet-login-concept-illustration_114360-7863.jpg"
            alt="Login Image"
            className="img-fluid"
          />
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
}

export default Sign;

import React from 'react';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Css/OrderDetails.css";
debugger;
const OrderDetails = ({ selectedCartItem, orderDetails, couponCode, setCouponCode, handleApplyCoupon }) => {

  // Calculate Bag total
  const bagTotal = selectedCartItem ? selectedCartItem.amount * selectedCartItem.quantity : 0;

  // Calculate Bag discount
  const bagDiscount = selectedCartItem ? selectedCartItem.discount_amount * selectedCartItem.quantity : 0;

  // Calculate Coupon amount (assuming it's applied)
  const couponAmount = couponCode ? 50 : 0; // Assuming coupon is applied and provides a fixed discount of $50

  // Calculate Order Total
  const orderTotal = bagTotal - bagDiscount - couponAmount;
  debugger;
  // Function to handle proceed to shipping
  const handleProceedToShipping = () => {
    // Retrieve required parameters from local storage
    const cartId = localStorage.getItem("cart_id");
    const authorId = localStorage.getItem("author_id");
    const addressBookId = localStorage.getItem('addressbookid');

    // Check if all required parameters are available
    if (authorId && cartId && addressBookId) {
      const data = {
        author_id: authorId,
        totalamount: orderTotal,
        addressbook_id: addressBookId,
        cart_id: cartId,
        usertype: 4
      };

      debugger;
      fetch('https://itilmaamapi.adef.tech/Create_Bill', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: JSON.stringify(data)
      })
      .then(response => {
        if (response.ok) {
          // Show success notification
          toast.success('Order placed successfully!');
          localStorage.removeItem("cart_id");
          window.location.href="/myorders";
        } else {
          // Show error notification
          toast.error('Failed to create order. Please try again later.');
        }
      })
      .catch(error => {
        console.error('Error creating order:', error);
        // Show error notification
        toast.error('An error occurred while placing the order.');
      });
    } else {
      // Show error notification if any parameter is missing
      toast.error('Missing required parameters to place the order.');
    }
  };
  

  debugger;
  return (
    <div className="order-details-container mt-4">
      <div className="order-details-content p-3">
        <h5 className="order-details-title">Order Details</h5>
        <div className="order-details-item mt-4 d-flex justify-content-between">
          <h6 className="order-details-label">Amount </h6>
          <h6 className="order-details-value">$. {bagTotal}.00</h6>
        </div>
        <div className="order-details-item mt-3 bag-discount d-flex justify-content-between">
          <h6 className="order-details-label">Discount </h6>
          <h6 className="order-details-value">-$ {bagDiscount}.00</h6>
        </div>
        <div className="order-details-item mt-3 bag-discount d-flex justify-content-between">
          <h6 className="order-details-label">Coupon </h6>
          <h6 className="order-details-value">-$ {couponAmount}.00</h6>
        </div>
        <div className="order-details-item mt-3 order-total d-flex justify-content-between">
          <h6 className="order-details-label">Total Bag</h6>
          <h6 className="order-details-value">$ {orderTotal}.00</h6>
        </div>

        <Link to="/delivery" className="proceed-to-shipping-link">
          <button className="proceed-to-shipping-btn" id="proceedbtn" onClick={handleProceedToShipping}>
            Proceed to Shipping
          </button>
        </Link>
      </div>

      {/* Coupon code section */}
      <div className="coupon-code p-3 mt-3">
        <h5 className="coupon-code-title">Coupon Code</h5>
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control coupon-code-input"
            placeholder="Enter coupon code"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
          />
          <div className="input-group-append">
            <button
              className="btn btn-outline-secondary apply-coupon-btn"
              type="button"
              onClick={handleApplyCoupon}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;

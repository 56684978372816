import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./Css/Cart.css";
import OrderDetails from "./OrderDetails";

function Cart() {
  const [cart, setCart] = useState([]);
  const [orderDetails, setOrderDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCartItem, setSelectedCartItem] = useState(null);
  const [couponCode, setCouponCode] = useState('');
  const [appliedCoupon, setAppliedCoupon] = useState(null);

  useEffect(() => {
    const fetchCartDetails = async () => {
      try {
        const cartId = localStorage.getItem("cart_id");
        const authorId = localStorage.getItem("author_id");

        if (!cartId || !authorId) {
          throw new Error("Cart ID or Author ID not found in localStorage");
        }

        const cartResponse = await axios.get(
          `https://itilmaamapi.adef.tech/Cartlist?cart_id=${cartId}&author_id=${authorId}`
        );

        if (cartResponse.status === 200) {
          setCart(cartResponse.data);
          setSelectedCartItem(cartResponse.data[0]); // Set the first item as selected initially
        }

        const orderResponse = await axios.get(
          `https://itilmaamapi.adef.tech/Cartamount?cart_id=${cartId}`
        );

        if (orderResponse.status === 200) {
          setOrderDetails(orderResponse.data);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching cart details:", error);
        setError("Error fetching cart details");
      }
    };

    fetchCartDetails();
  }, []);

  const handleQuantityChange = async (e, productId) => {
    const newQuantity = parseInt(e.target.value);
    const updatedCart = cart.map(item =>
      item.product_id === productId ? { ...item, quantity: newQuantity, price: item.amount * newQuantity } : item
    );
  
    try {
      // Update the quantity in the cart data on the server
      await updateCart(updatedCart);
  
      // Update the cart data in the local state
      setCart(updatedCart);
  
      // Update local storage
      updateLocalStorage(updatedCart);
  
      // Recalculate the order total
      const newOrderDetails = calculateOrderTotal(updatedCart);
      setOrderDetails(newOrderDetails);
    } catch (error) {
      console.error("Error updating quantity:", error);
      // Handle error
    }
  };
  

  // Function to delete item from cart
  const handleDeleteItem = async (itemId) => {
    try {
      const response = await axios.post(
        "https://itilmaamapi.adef.tech/DeleteCart",
        { id: itemId },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      );

      if (response.status === 200) {
        // If the deletion was successful, update the cart and order details
        const updatedCart = cart.filter(item => item.id !== itemId);
        setCart(updatedCart);
        const newOrderDetails = calculateOrderTotal(updatedCart);
        setOrderDetails(newOrderDetails);
      } else {
        throw new Error("Failed to delete item from cart");
      }
    } catch (error) {
      console.error("Error deleting item:", error);
      // Handle error
    }
  };

  // Function to update cart
  const updateCart = async (updatedCart, id) => {
    try {
      const authorId = localStorage.getItem("author_id");
      const userType = 4;

      const requestData = {
        id: id, // Assuming id is passed as an argument
        author_id: authorId,
        usertype: userType,
        cart_details: updatedCart
      };

      const response = await axios.post(
        "https://itilmaamapi.adef.tech/UpdateCart",
        requestData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      );

      if (response.status !== 200) {
        throw new Error("Failed to update cart");
      }
    } catch (error) {
      console.error("Error updating cart:", error);
      throw error;
    }
  };

  // Function to calculate order total based on the updated cart
  const calculateOrderTotal = (cart) => {
    let totalPrice = 0;
    let totalDiscount = 0;
    let totalCoupon = 0;

    cart.forEach(item => {
      totalPrice += item.price * item.quantity;
      totalDiscount += item.discount_amount * item.quantity;
      totalCoupon += item.coupon_amount * item.quantity;
    });

    const totalCartAmount = totalPrice - totalDiscount - totalCoupon;

    return {
      price: totalPrice,
      discount_amount: totalDiscount,
      coupon_amount: totalCoupon,
      total_cart_amount: totalCartAmount
    };
  };

  // Function to handle click on a cart item
  const handleCartItemClicked = (item) => {
    setSelectedCartItem(item);
  };

  // Function to handle applying coupon code
  const handleApplyCoupon = () => {
    // Here you would implement logic to validate and apply the coupon code
    // For simplicity, let's assume the coupon code is valid and provides a fixed discount
    const couponDiscount = 50; // Example fixed discount amount
    setAppliedCoupon({
      code: couponCode,
      discount: couponDiscount
    });

    // Recalculate the order total after applying the coupon
    const newOrderDetails = {
      ...orderDetails,
      total_cart_amount: orderDetails.total_cart_amount - couponDiscount
    };
    setOrderDetails(newOrderDetails);
  };

  // Function to update local storage with the updated cart data
  const updateLocalStorage = (updatedCart) => {
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  return (
    <div className="container-fluid mt-md-5" style={{ width: "95%" }}>
      <h1 className="mt-md-5 mybag">
        My Cart <span className="mybag">(<span className="mybag item_count"></span> item)</span>
      </h1>

      <div className="row">
        <div className="col-md-9 cart_list  mt-4" id="cart_list">
          {/* Cart items */}
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>{error}</p>
          ) : cart.length > 0 ? (
            cart.map((item, index) => (
              <div
                key={index}
                className={`row mb-3 ${selectedCartItem === item ? 'selected' : ''}`}
                style={{ border: "1px solid #808080" }}
                onClick={() => handleCartItemClicked(item)}
              >
                {/* Cart item details */}
                <div className="col-md-2 checkout-img">
                  <img src={item.image} alt={item.name} />
                </div>
                <div className="col-md-4 pt-md-4">
                  <h5>{item.name}</h5>
                  <h5>{item.category}</h5>
                </div>
                <div className="col-md-6 p-3">
                  <div className="d-flex">
                    <div>
                      <span className="qty-headings">Qty</span>
                      <select
                        className="mx-3"
                        value={item.quantity}
                        onChange={(e) => handleQuantityChange(e, item.product_id)}
                      >
                        {[...Array(10).keys()].map((index) => (
                          <option key={index} value={index + 1}>{index + 1}</option>
                        ))}
                      </select>
                    </div>
                    <div className="saving_mrp">
                      <span className="qty-headings">Savings :</span>
                      <span className="mx-3 mrp">$. {item.discount_amount}</span>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end">
                    <div className="p-4">
                      <div>
                        <div>

                          <span style={{ marginRight: "20px", color: "#8FBC25", textDecoration: "line-through" }}>
                            $. {item.amount}.00
                          </span>
                          {item.discount_amount && item.amount && (
                            <span className="discount-percentage">
                              ({((item.discount_amount / item.amount) * 100)}%)
                            </span>
                          )}

                          {/* Discounted price */}
                          <span className="endprice"> $.{item.price}.00</span>

                          {/* Percentage of discount */}

                        </div>


                      </div>
                      <div className="d-flex justify-content-end mt-2">
                        <button className="btn delete-button" onClick={() => handleDeleteItem(item.id)}>Delete</button>
                        <button className="wishlist-button mx-3"><i className="fa-regular fa-heart"></i>Move to wishlist</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No items in the cart</p>
          )}
        </div>
        <div className="col-md-3">
          {cart.length > 0 && (
            <OrderDetails
              selectedCartItem={selectedCartItem}
              orderDetails={orderDetails}
              couponCode={couponCode}
              setCouponCode={setCouponCode}
              handleApplyCoupon={handleApplyCoupon}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Cart;
